<template>
   <div class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>全员营销</el-breadcrumb-item>
            <el-breadcrumb-item>推广效果</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 查询框 -->
      <el-row class="content-box">
         <div class="right-tabbox-newnotice">
            <!--表格类型-->
            <!--            type="border-card"-->
            <el-tabs v-model="activeName" @tab-click="handleClick">
               <el-tab-pane v-for="(item,index) in tabslist"   :key="index" :label="item.label" :name="item.name">
                  <el-row class="search-box">
                     <el-row class="search-row">
                        <!-- 推广订单搜索栏 -->
                        <el-row class="search-item" v-if="activeName == 'promotion' ">
                           <label>下单时间：</label>
                           <el-radio-group v-model="checkboxGroup" @change="radioChange" class="group-box">
                              <el-radio-button label="1" :class="checkboxGroup == 1 ? 'bg-gradient' : ''">全部
                              </el-radio-button>
                              <el-radio-button label="2" :class="checkboxGroup == 2 ? 'bg-gradient' : ''">本周
                              </el-radio-button>
                              <el-radio-button label="3" :class="checkboxGroup == 3 ? 'bg-gradient' : ''">本月
                              </el-radio-button>
                              <el-radio-button label="4" :class="checkboxGroup == 4 ? 'bg-gradient' : ''">自定义
                              </el-radio-button>
                           </el-radio-group>
                           <el-date-picker
                                 class="width-300 mg15"
                                 v-if="checkboxGroup == '4' "
                                 v-model="pickerTime" type="daterange" range-separator="至"
                                 start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                 @change="handleTime">
                           </el-date-picker>
                        </el-row>

                     </el-row>
                     <!-- 业绩统计搜索栏 -->
                     <el-row class="search-row" v-if="activeName == 'performance' ">
                        <label style="line-height: 40px;">分销员名称/手机号：</label>
                        <el-input class="width-180 m-left-5" v-model="condition"></el-input>

                        <el-button style="margin-left: 100px" type="primary" class="add-btn bg-gradient mg15"
                                   @click="inquiredata">查询
                        </el-button>
                        <el-button class="add-btn bg-gradient mg15" @click="reset">重置</el-button>
                        <el-button type="primary" class="add-btn right-btn bg-gradient" @click="exportdatalist">导出数据
                        </el-button>

                     </el-row>

                     <!--商品推广效果搜索栏-->
                     <el-row class="search-row" v-if="activeName == 'result'  ">
                        <el-row class="search-item" style="margin: 0 50px 0 0;">
                           <label>商品名称：</label>
                           <el-input class="width-180 m-left-5" v-model="productName"></el-input>
                        </el-row>

                        <el-row class="search-item" v-if="activeName == 'result' " style="margin: 0 50px 0 0;">
                           <label>商品类型：</label>
                           <el-select v-model="type" clearable class="width-180 " :placeholder="$t('msg.select')">
                              <el-option v-for="(item,index) in dictData['order-type']" :key="index" :label="item"
                                         :value="index"></el-option>
                           </el-select>
                        </el-row>

                        <el-row class="search-item" v-if="activeName == 'result' " style="margin: 0 50px 0 0;">
                           <label>推广状态：</label>
                           <el-select v-model="state" class="width-180 " clearable :placeholder="$t('msg.select')">
                              <el-option
                                    v-for="item in promotionstatelist"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.state">
                              </el-option>
                           </el-select>
                        </el-row>
                        <el-button type="primary" class="add-btn bg-gradient " @click="inquiredata">
                           查询
                        </el-button>
                        <el-button class="add-btn bg-gradient " @click="reset">重置</el-button>
                        <el-button type="primary" class="add-btn right-btn bg-gradient" @click="exportdatalist">导出数据
                        </el-button>

                     </el-row>

                  </el-row>
                  <!--推广订单搜索栏-->
                  <el-row class="search-box" v-if="activeName == 'promotion' ">
                     <el-row class="search-row">
                        <el-row class="search-item">
                           <label>分销员名称/手机号：</label>
                           <el-input class="width-180 " v-model="distributorCondition"></el-input>
                        </el-row>

                        <el-row class="search-item">
                           <label>客户名称/手机号：</label>
                           <el-input class="width-180 " v-model="customerCondition"></el-input>
                        </el-row>
                        <el-row class="search-item">
                           <label>订单号：</label>
                           <el-input class="width-180 " v-model="orderNo"></el-input>
                        </el-row>

                     </el-row>
                  </el-row>
                  <el-row class="search-box" v-if="activeName == 'promotion' ">
                     <el-row class="search-row">
                        <el-row class="search-item">
                           <label class="mg72">交易状态：</label>
                           <el-select v-model="state" clearable :placeholder="$t('msg.select')" style="width: 180px">
                              <el-option
                                    v-for="item in statelist"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.state">
                              </el-option>
                           </el-select>
                        </el-row>
                        <el-button type="primary" class="add-btn bg-gradient" @click="inquiredata">查询</el-button>
                        <el-button class="add-btn bg-gradient " @click="reset">重置</el-button>
                        <el-button type="primary" class="add-btn right-btn bg-gradient" @click="exportdatalist">导出数据
                        </el-button>

                     </el-row>
                  </el-row>

                  <!-- 表格 -->

                  <el-row class="table-box">

                     <el-table
                           ref="mainTable"
                           border
                           v-loading='loading'
                           :data="tableData"
                           :stripe="true"
                     >
                        <el-table-column prop="hotelName" key="1" label="酒店名称" min-width="100"></el-table-column>
                        <!-- 推广-->
                        <template v-if="activeName == 'promotion' ">
                           <el-table-column label="商品类型" key="2"  min-width="90">
                              <template slot-scope="scope">
                                 {{ scope.row.orderType | filterOrderType(that) }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="distributorInformation" key="3" min-width="200" label="分销员信息" :show-overflow-tooltip="true"></el-table-column>
                           <el-table-column prop="customerInformation" key="4" min-width="200" label="客户信息"  :show-overflow-tooltip="true"></el-table-column>
                           <el-table-column prop="orderNo" key="5" label="订单号" min-width="210"  ></el-table-column>
                           <el-table-column prop="createTime" key="6" label="下单时间"  min-width="100"></el-table-column>
                           <el-table-column prop="actualAmount" key="7" label="实收金额" min-width="100" ></el-table-column>
                           <el-table-column prop="distributorName" key="10" label="间推人" min-width="120" ></el-table-column>
                           <el-table-column prop="state" key="11" label="交易状态" min-width="110" >
                              <template slot-scope="scope">
                                 {{ scope.row.state | filterStatus }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="awardAmount1" key="12" label="直推佣金" min-width="100" >
                              <template slot-scope="scope">
                                 {{ scope.row.awardAmount1 || '-' }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="awardAmount2" key="13" label="间推佣金" min-width="100" >
                              <template slot-scope="scope">
                                 {{ scope.row.awardAmount2 || '-' }}
                              </template>
                           </el-table-column>
                        </template>
                        <!--业绩-->
                        <template v-if="activeName == 'performance' ">
                           <el-table-column prop="memberDTO.nickName" key="2" label="分销员信息" min-width="120"></el-table-column>
                           <el-table-column prop="customerNum" key="3" label="客户数" min-width="80" ></el-table-column>
                           <el-table-column prop="integerinvitedNum" key="4" label="邀请人数" min-width="110" ></el-table-column>
                           <el-table-column prop="orderSum1" key="5" label="直推成交金额" min-width="160" ></el-table-column>
                           <el-table-column prop="commissionSum1" key="6" label="直推佣金" min-width="110" >
                              <template slot-scope="scope">
                                 {{ scope.row.commissionSum1 || '-' }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="commissionSum2" key="7" label="间推佣金" min-width="110" >
                              <template slot-scope="scope">
                                 {{ scope.row.commissionSum2 || '-' }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="inTradeCommission" key="8" label="待结算佣金" min-width="110" >
                              <template slot-scope="scope">
                                 {{ scope.row.inTradeCommission || '-' }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="totalCommission" key="9" label="累积佣金" min-width="110"  ></el-table-column>

                        </template>
                        <!--效果-->
                        <template v-if="activeName == 'result' ">
                           <el-table-column label="商品类型" key="3"  min-width="90">
                              <template slot-scope="scope">
                                 {{ scope.row.type | filterOrderType(that) }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="productName" key="4" label="商品名称"  min-width="180">
                              <template slot-scope="scope">
                                 {{ scope.row.productName || '所有商品' }}
                              </template>
                           </el-table-column>
                           <el-table-column label="推广时间" key="5" min-width="180">
                              <template slot-scope="scope">
<!--                                 {{ scope.row.orderType | filterOrderType(that) }}-->
                                 <el-row v-if="scope.row.timeType === 'EVERY_DAY'">
                                    每天
                                 </el-row>
                                 <div v-else>{{ scope.row.beginTime }}至{{ scope.row.endTime }}</div>

                              </template>
                           </el-table-column>
                           <el-table-column label="推广状态" key="6"  min-width="90">
                              <template slot-scope="scope">
                                 {{ scope.row.state | filterpromotionStatus }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="dealQuantity" key="7" label="销量"  min-width="60"></el-table-column>
                           <el-table-column prop="awardAmount1" key="8" label="直推佣金" min-width="110" >
                              <template slot-scope="scope">
                                 {{ scope.row.awardAmount1 || '-' }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="awardAmount2" key="9" label="间推佣金" min-width="110">
                              <template slot-scope="scope">
                                 {{ scope.row.awardAmount2 || '-' }}
                              </template>
                           </el-table-column>
                           <el-table-column prop="productPrice" key="10" label="客单价"  min-width="80"></el-table-column>
                           <el-table-column prop="dealAmount" key="11" label="成交金额"></el-table-column>

                        </template>

                     </el-table>
                     <!-- 分页 -->
                     <pagination :total="total" :page-size="limit" v-if="pageshow" @handleSizeChangeSub="couponPageNum" @handleCurrentChangeSub="couponCurrPage"/>
                  </el-row>
               </el-tab-pane>
            </el-tabs>
         </div>
      </el-row>
   </div>
</template>

<script>
   import { urlObj, smtech } from '@/api/interface'
   import { marketing } from '@/api/interface/smtech'
   import  {getDict,  exportFile, dateFactory} from '@/common/js/common'
   import { mapState } from "vuex";
   export default {
      data() {
         return {
            that: this,
            pageshow: true,
            activeName: 'promotion',   // tab栏
            tabslist: [//tab列表
               {name: 'promotion', label: '推广订单'},
               {name: 'performance', label: '业绩统计'},
               {name: 'result', label: '商品推广效果'}
            ],
            statelist: [//支付状态列表
               {state: 'SUCCESS', label: '支付成功'},
               {state: 'FAIL', label: '支付失败'},
               {state: 'TO_BE_DIVIDED', label: '待分账'},
               {state: 'CANCEL', label: '已取消'}
            ],
            promotionstatelist: [ //推广状态列表
               {state: 'NOT_PROMOTED', label: '未推广'},
               {state: 'UNDER_PROMOTION', label: '推广中'}
            ],
            tableData: [],//表格数据
            loading:true,
            pickerTime: [],//订单时间
            total: 0,//数据总数
            checkboxGroup: "1",//时间类型
            orderNo: '',//订单号
            productName: '',//商品名称
            condition: '',
            customerCondition: '',
            distributorCondition: '',
            state: '',//推广状态
            page: 1,
            tabindex: 0,//tab页的下标
            type: '',//商品类型
            limit: 10,

         }
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.hotelCode = this.hotelInfo.hotelCode
                  if (this.tabindex == 0) {
                     this.getSeckillCoupons()
                  }
                  if (this.tabindex == 1) {
                     this.getStatisticslist()
                  }
                  if (this.tabindex == 2) {
                     this.getEffectOfCommodityPromotion()
                  }
               }
            }
         }
      },
      computed: {
         ...mapState(['hotelInfo', 'dictData'])
      },
      mounted() {
         this.hotelId = this.hotelInfo.id
         getDict(['order-type', 'order-status'])
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getSeckillCoupons()
      },

      methods: {

         radioChange() {
            if (this.checkboxGroup == '1' || this.checkboxGroup == '4'){
               this.pageshow = false
               this.page = 1
               this.$nextTick(() => {
                  this.pageshow = true
               })
               this.pickerTime = []
            }

            if (this.checkboxGroup == '2') {
               this.pageshow = false
               this.page = 1
               this.$nextTick(() => {
                  this.pageshow = true
               })
               let weekOfday = dateFactory.getWeekList()
               this.pickerTime = [weekOfday[0],weekOfday[6]]
            }
            if (this.checkboxGroup == '3') {
               this.pageshow = false
               this.page = 1
               this.$nextTick(() => {
                  this.pageshow = true
               })
               let date = new Date();
               let nowYear = date.getFullYear();
               let nowMonth = date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
               let monthDaynum = new Date(nowYear, nowMonth, 0).getDate() //本月天数
               let arr = [nowYear + '-' + nowMonth + '-' + "01",nowYear + '-' + nowMonth + '-' + monthDaynum]
               this.pickerTime = arr
            }
            this.getSeckillCoupons()
         },
         //查询
         inquiredata(){
            this.pageshow = false
            this.page = 1
            this.$nextTick(() => {
               this.pageshow = true
            })
            if (this.tabindex == 0) {
               this.getSeckillCoupons()
            }
            if (this.tabindex == 1) {
               this.getStatisticslist()
            }
            if (this.tabindex == 2) {
               this.getEffectOfCommodityPromotion()
            }
         },

         //tab页面切换
         handleClick(tab) {
            this.tabindex = tab.index
            this.reset()
         },
         //导出数据
         exportdatalist() {
            if (this.tabindex == 0) {
               this.exportPromotionOrder()
            }
            if (this.tabindex == 1) {
               this.exportStatisticsExcel()
            }
            if (this.tabindex == 2) {
               this.exportCommodityExcel()
            }
         },
         //导出商品推广效果
         exportCommodityExcel() {
            const url = marketing.exportCommodityExcel
            const param = {
               limit: 1000,
               page: 1,
               hotelId: this.hotelInfo.id,
               hotelName: this.hotelInfo.hotelName,
               state: this.state,
               productName: this.productName,
               type: this.type
            }
            this.$axios.get(url, param, {}, 'blob').then(res => {
               let data = res
               exportFile(data, '商品推广效果报表')
            })
         },
         //导出推广订单
         exportPromotionOrder() {
            const url = marketing.exportPromotionOrder
            const param = {
               limit: 1000,
               page: 1,
               hotelId: this.hotelInfo.id,
               hotelName: this.hotelInfo.hotelName,
               orderNo: this.orderNo,
               distributorCondition: this.distributorCondition,
               customerCondition: this.customerCondition,
               type: this.type,
            }
            this.$axios.get(url, param, {}, 'blob').then(res => {
               let data = res
               exportFile(data, '推广订单报表')
            })
         },
         //导出业绩统计
         exportStatisticsExcel() {
            const url = marketing.exportStatisticsExcel
            let param = {
               limit: 1000,
               page: 1,
               hotelId: this.hotelInfo.id,
               hotelName: this.hotelInfo.hotelName,
               condition: this.condition,

            }
            this.$axios.get(url, param, {}, 'blob').then(res => {
               let data = res
               exportFile(data, '导出业绩统计')
            })
         },
         //业绩统计
         getStatisticslist() {
            const url = marketing.getStatisticslist + `?limit=${this.limit}&page=${this.page}`
            const param = {
               hotelId: this.hotelInfo.id,
               condition: this.condition,

            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.total = res.total
                  this.loading = false
                  this.tableData = res.records
                  this.tableData.forEach(item => {
                     item.hotelName = this.hotelInfo.hotelName
                  })
               }
            })
         },

         //获取商品推广效果
         getEffectOfCommodityPromotion() {
            const url = marketing.getEffectOfCommodityPromotion + `?limit=${this.limit}&page=${this.page}`
            const param = {
               orderNo: this.orderNo,
               hotelId: this.hotelInfo.id,
               type: this.type,
               productName: this.productName,
               state: this.state,
               distributorInformation: this.distributorInformation,
               customerInformation: this.customerInformation,

            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.total = res.total
                 this.loading = false
                  this.tableData = res.records
                  this.tableData.forEach(item => {
                     item.hotelName = this.hotelInfo.hotelName
                     if (item.hasOwnProperty('beginTime')) {
                        item.beginTime = (item.beginTime).substring(0, 10)
                     }
                     if (item.hasOwnProperty('endTime')) {
                        item.endTime = (item.endTime).substring(0, 10)
                     }
                  })

               }
            })
         },
         //获取时间组件值
         handleTime(val) {
            this.pickerTime = val
            this.getSeckillCoupons()
         },
         // 获取推广订单
         getSeckillCoupons() {
            if (this.pickerTime.length != 0) {

               this.pickerTime[0] = this.pickerTime[0] + ' 00:00:01'
               this.pickerTime[1] = this.pickerTime[1] + ' 23:59:59'
            }
            const url = marketing.getPromotionOrderPage + `?limit=${this.limit}&page=${this.page}`
            const param = {
               hotelId: this.hotelInfo.id,
               orderNo: this.orderNo,
               productName: this.productName,
               state: this.state,
               distributorCondition: this.distributorCondition,
               customerCondition: this.customerCondition,
               beginTime: this.pickerTime[0],
               endTime: this.pickerTime[1],

            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.total = res.total
                 this.loading = false
                  this.tableData = res.records
                  this.tableData.forEach(item => {
                     item.hotelName = this.hotelInfo.hotelName
                     item.createTime = item.createTime.substring(0, 10)
                  })
                  if (this.pickerTime.length !=0){
                     this.pickerTime[0] = this.pickerTime[0].substring(0, 10)
                     this.pickerTime[1] = this.pickerTime[1].substring(0, 10)
                  }
               }
            })
         },
         //重置
         reset() {
            this.checkboxGroup = 1
            this.state = ''
            this.distributorCondition = ''
            this.orderNo = ''
            this.productName = ''
            this.type = ''
            this.customerCondition = ''
            this.pickerTime = []
            this.pageshow = false
            this.condition = ''
            this.page = 1
            this.$nextTick(() => {
               this.pageshow = true
            })
            if (this.tabindex == 0) {
               this.getSeckillCoupons()
            }
            if (this.tabindex == 1) {
               this.getStatisticslist()
            }
            if (this.tabindex == 2) {
               this.getEffectOfCommodityPromotion()
            }
         },
         // 改变每页数
         couponPageNum(num) {
            this.limit = num
            if (this.tabindex == 0) {
               this.getSeckillCoupons()
            }
            if (this.tabindex == 1) {
               this.getStatisticslist()
            }
            if (this.tabindex == 2) {
               this.getEffectOfCommodityPromotion()
            }
         },
         // 改变当前页
         couponCurrPage(num) {
            this.page = num
            if (this.tabindex == 0) {
               this.getSeckillCoupons()
            }
            if (this.tabindex == 1) {
               this.getStatisticslist()
            }
            if (this.tabindex == 2) {
               this.getEffectOfCommodityPromotion()
            }
         },


      },
      filters: {
         filterStatus(val) {
            switch (val) {
               case 'SUCCESS':
                  return val = '支付成功'
               case 'FAIL':
                  return val = '支付失败'
               case 'TO_BE_DIVIDED':
                  return val = '待分账'
               case 'CANCEL':
                  return val = '已取消'
            }
         },
         filterpromotionStatus(val) {
            switch (val) {
               case 'UNDER_PROMOTION':
                  return val = '推广中'
               case 'NOT_PROMOTED':
                  return val = '未推广'
            }
         },
         filterOrderType(val, that) {
            let dictData = sessionStorage.getItem('dictData');
            if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
         },
      },
   }
</script>

<style lang="scss" scoped>
   .cont {
      .search-box {
         display: flex;

         .search-row {
            .search-item {
               margin: 0 100px 0 0;
            }

            .group-box {
               ::v-deep .el-radio-button__inner {
                  background: transparent;
               }
            }
         }
      }
      .table-box {
         .add-btn {
            margin-bottom: 10px;
            float: right
         }
         .add-btn {
            margin-bottom: 30px;
            float: right;
            width: 98px
         }
         .member-info {
            display: flex;
            img {
               width: 60px;
               margin-right: 10px
            }
         }
         .right-tabbox-newnotice {
            margin-top: 40px;
         }
      }
      .des {
         color: red;
         margin-right: 5px
      }
      .mg15 {
         margin: 0 15px;
         width: 98px;
      }
      .mg72 {
         margin-left: 72px;
      }
   }

   /deep/ .el-tabs__nav-scroll {
      margin-bottom: 15px;
   }

   /deep/ .el-table th.gutter {
      /*
      * 解决element-ui 表格篡位的问题 👇
      */
      display: table-cell !important;
   }

   /deep/ .el-table--enable-row-transition .el-table__body td {
      // text-align: center;
      border-bottom: 1px solid #EBEEF5;

   }

   /deep/ .el-table th.is-leaf, .el-table td {
      // text-align: center;
      background: #f5f8f9;
   }
</style>
